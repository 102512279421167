<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.maxActiveResOnAccountEditForm"
    />
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">
          Maximum Active Reservations On Account View/Edit
        </div>
      </div>
      <div class="card-body">
        <div v-if="loading" class="my-5 text-center">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
        <div v-else>
          <ValidationObserver ref="maxActiveResOnAccountEditForm">
            <form @submit.prevent="onSubmit">
              <AdminCommonBusinessRuleFields
                :view="boolView"
                :rule="maxActiveResOnAccountRule"
                :referenceId="refId"
              />
              <hr />
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="required|between:1,20"
                    name="Maximum Active Reservations On Account"
                    id="ruleLength"
                    placeholder="Enter maximum active reservations on account"
                    v-model="maxActiveResOnAccountRule.length"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Maximum active reservations are upcoming reservations that
                    have been paid in full.
                  </small>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Created By"
                    id="createdBy"
                    class="mb-2"
                    v-model="maxActiveResOnAccountRule.createdBy"
                    :disabled="true"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Date Created"
                    id="dateCreated"
                    class="mb-2"
                    v-model="maxActiveResOnAccountRule.dateCreated"
                    :disabled="true"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Last Modified By"
                    id="lastModifiedBy"
                    class="mb-2"
                    v-model="maxActiveResOnAccountRule.lastModifiedBy"
                    :disabled="true"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Date Last Modified"
                    id="dateLastModified"
                    class="mb-2"
                    v-model="maxActiveResOnAccountRule.dateLastModified"
                    :disabled="true"
                  />
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-lg btn-primary mt-3"
                v-show="!boolView"
              >
                Update
              </button>
              <button
                type="button"
                class="btn btn-lg btn-primary mt-3"
                v-show="boolView"
                @click="edit"
              >
                Edit
              </button>
              <button
                class="btn btn-lg btn-secondary mt-3 ml-1"
                type="button"
                @click="goBack"
              >
                Back
              </button>
              <button
                class="btn btn-cancel btn-lg mt-3 ml-1"
                type="button"
                v-show="!boolView"
                @click="cancel"
              >
                Cancel
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AdminCommonBusinessRuleFields from "@/components/admin/AdminCommonBusinessRuleFields.vue";
import AdminBusinessRuleService from "@/services/admin/AdminBusinessRuleService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";

export default {
  name: "AdminMaximumActiveReservationsOnAccountView",
  title: "Admin - Maximum Active Reservations On Account View/Edit",
  components: {
    ValidationObserver,
    AdminCommonBusinessRuleFields,
    FormErrorAlert,
    TextInput
  },
  props: {
    view: String,
    id: String,
    refId: String
  },
  data() {
    return {
      maxActiveResOnAccountRule: {},
      booleanChoices: [
        { key: "Yes", value: true },
        { key: "No", value: false }
      ],
      errors: [],
      availableProductClasses: [],
      loading: false,
      boolView: true
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    goBack() {
      this.$router
        .push("/admin/brm/maximum-active-reservations-on-account")
        .catch(() => {});
    },
    edit() {
      this.boolView = false;
      this.$router
        .push(
          `/admin/brm/maximum-active-reservations-on-account/${this.id}?refId=${this.refId}&v=false`
        )
        .catch(() => {});
    },
    cancel() {
      this.loadRule();
      this.boolView = true;
    },
    async initialize() {
      const dataService = new AdminProductManagementService(this.tenantId);
      const classes = dataService.getAllProductClasses();
      await Promise.allSettled([classes]).then(responses => {
        this.availableProductClasses = responses[0].value;
      });
    },
    async loadRule() {
      this.loading = true;
      if (this.view === "false") this.boolView = false;
      await this.initialize();
      const brService = new AdminBusinessRuleService(this.tenantId);
      brService.getMaxActiveRes(this.id).then(response => {
        const {
          id,
          createdBy,
          lastModifiedBy,
          areaId,
          comments,
          isActive,
          length,
          loopId,
          paymentTypeId,
          productId,
          productTypeId,
          salesChannelId,
          seasonTypeId,
          validationMessage,
          businessRuleProductClassifications
        } = response.data;

        let {
          startDate,
          endDate,
          dateCreated,
          dateLastModified
        } = response.data;

        startDate = this.$options.filters.formatUTCDate(
          startDate.toISOString()
        );
        endDate = this.$options.filters.formatUTCDate(endDate.toISOString());
        dateCreated = this.$options.filters.formatUTCDateTime(
          dateCreated.toISOString()
        );
        dateLastModified = this.$options.filters.formatUTCDateTime(
          dateLastModified.toISOString()
        );

        this.maxActiveResOnAccountRule = {
          name: "Maximum Active Reservations On Account",
          id,
          createdBy,
          dateCreated,
          lastModifiedBy,
          dateLastModified,
          areaId: areaId === null ? "" : areaId,
          comments,
          startDate,
          endDate,
          isActive,
          length,
          loopId: loopId === null ? "" : loopId,
          paymentTypeId: paymentTypeId === null ? "" : paymentTypeId,
          productId: productId === null ? "" : productId,
          productTypeId: productTypeId === null ? "" : productTypeId,
          salesChannelId: salesChannelId === null ? "" : salesChannelId,
          seasonTypeId: seasonTypeId === null ? "" : seasonTypeId,
          validationMessage,
          productClasses: this.availableProductClasses.filter(n =>
            businessRuleProductClassifications.some(
              x => x.productClassificationId === n.id
            )
          )
        };

        this.loading = false;
      });
    },
    async onSubmit() {
      this.$refs.maxActiveResOnAccountEditForm
        .validate()
        .then(async success => {
          if (!success) {
            setTimeout(() => {
              const errors = Object.entries(
                this.$refs.maxActiveResOnAccountEditForm.errors
              )
                .map(([key, value]) => ({ key, value }))
                .filter(error => error["value"].length);
              this.errors = errors;
              this.$refs.maxActiveResOnAccountEditForm.refs[
                errors[0]["key"]
              ].$el.scrollIntoView({
                behavior: "smooth",
                block: "center"
              });
            }, 100);
          } else {
            var request = {
              id: this.maxActiveResOnAccountRule.id,
              isActive: this.maxActiveResOnAccountRule.isActive,
              productId: this.maxActiveResOnAccountRule.productId,
              productTypeId: this.maxActiveResOnAccountRule.productTypeId,
              areaId: this.maxActiveResOnAccountRule.areaId,
              loopId: this.maxActiveResOnAccountRule.loopId,
              salesChannelId: this.maxActiveResOnAccountRule.salesChannelId,
              seasonTypeId: this.maxActiveResOnAccountRule.seasonTypeId,
              paymentTypeId: this.maxActiveResOnAccountRule.seasonTypeId,
              comments: this.maxActiveResOnAccountRule.comments,
              startDate: this.maxActiveResOnAccountRule.startDate,
              endDate: this.maxActiveResOnAccountRule.endDate,
              length: this.maxActiveResOnAccountRule.length,
              validationMessage: this.maxActiveResOnAccountRule
                .validationMessage
            };
            if (this.maxActiveResOnAccountRule.productClasses) {
              request.productClassIds = this.maxActiveResOnAccountRule.productClasses.map(
                n => n.id
              );
            }
            const brService = new AdminBusinessRuleService(this.tenantId);
            const response = await brService.editMaximumActiveReservations(
              request
            );
            window.scrollTo(0, 0);
            if (response?.statusCode == "Success") {
              this.loadRule();
              this.boolView = true;
              this.$router
                .push(
                  `/admin/brm/maximum-active-reservations-on-account/${this.id}?refId=${this.refId}&v=true`
                )
                .catch(() => {});
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Business Rule Updated",
                layer: "admin"
              });
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
            this.errors = [];
          }
        });
    }
  },
  created() {
    this.loadRule();
  }
};
</script>
